<template>
    <div>
        <el-table stripe border v-loading="loading" :data="dataList" >
            <el-table-column label="商户编号" prop="merchantNo" min-width="200" :show-overflow-tooltip="true"/>
            <el-table-column label="渠道" prop="channelNo" min-width="120" :show-overflow-tooltip="true" >
                <template slot-scope="scope">
                    {{ scope.row.channelNo | channelFM }}
                </template>
            </el-table-column>
            <el-table-column label="商户简称" prop="lsMerchantName" min-width="200" :show-overflow-tooltip="true"/>
            <el-table-column label="所属代理商" prop="agentName" min-width="200" :show-overflow-tooltip="true"/>
            <el-table-column label="所属代理商编号" prop="agentNo" min-width="140" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="商户状态" prop="merStatus" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.merchantStatus=='1'?'审核成功':''}}
                </template>
            </el-table-column>
            <el-table-column label="注册时间" prop="createTime" min-width="180" :show-overflow-tooltip="true"/>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="150" fixed="right">
                <template slot-scope="scope">
                    <el-button size="mini" type="text"
                               @click="handleDetail(scope.row)"
                               v-permission="'AGENT_INFO_DETAIL'">详情</el-button>  
                </template>
            </el-table-column>
        </el-table>

        <Pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize" @pagination="getList"/>
    </div>
</template>

<script>
    import {MerchantApi} from '@/api';
    import { mapState } from 'vuex'
    export default {
        name: "ToolBar",
        props: {
            dicts: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            queryParams: {
                type: Object,
                default: function () {
                    return {}
                }
            },
        },
        data() {
            return {
                pageNo: 1,
                pageSize: 10,
                loading: false,
                total: 0,
                dataList: [],
                selectRow: {},
                showCustomForm: false,
                isAdd: false
            }
        },
        computed:{
            ...mapState('app', ['userInfo']),
            isOrg(){
                return this.userInfo.org
            },
        },
        watch: {
            queryParams: function () {
                this.pageNo = 1;
                this.getList();
            },
        },
        methods: {
            async getList() {
                this.loading = true;
                let result = await MerchantApi.getMerchantlist(this.pageNo, this.pageSize, this.queryParams);
                this.loading = false;
                this.total = result.count || 0;
                this.dataList = result.data || [];
            },
            handlerCustomFormOnclose() {
                this.showCustomForm = false;
                this.getList();
            },
            handleStatusChange(row) {
                let text = row.status === "DISABLE" ? "禁用" : "启用";
                this.$confirm("是否确定"+text+"?","警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(async () => {
                    let result = await MerchantApi.agentInfo.edit(row.agentNo, {
                        state: row.state
                    });
                    if (result.success) {
                        this.getList();
                    }
                }).catch(() => this.getList());
            },
            handleUpdate(row) {
                this.$router.push({
                    name:'merchantUpdate',
                    query:{
                        merchantNo: row.merchantNo
                    }

                })
            },
            handleDelete(row){
                this.$confirm("是否确定删除业务用户（包含机构/代理商）编号为"+row.agentNo+"的数据项}?",
                        "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(async () => {
                    let result = await MerchantApi.agentInfo.remove(row.agentNo);
                    if (result.success) {
                        this.Message.success("删除成功");
                        this.getList();
                    }
                });
            },
            handleDetail(row){
                this.$router.push({
                    name:'merchantDetail',
                    query:{
                        merchantNo: row.merchantNo
                    }
                })
            }
        }
    };
</script>

<style scoped>

</style>
