<template>
	<div>
		<el-form
			ref="queryForm"
			class="query-box"
			v-model="queryParams"
			:inline="true"
		>
			<el-form-item label="注册时间" label-width="140px">
				<el-date-picker
					v-model="createDateRange"
					size="small"
					value-format="yyyy-MM-dd"
					type="daterange"
					range-separator="-"
					style="width: 240px"
					@change="getDateRange"
					start-placeholder="开始时间"
					end-placeholder="结束时间"
				/>
			</el-form-item>
			<el-form-item
				label="商户编号"
				prop="merchantNo"
				label-width="140px"
			>
				<el-input
					v-model="queryParams.merchantNo"
					clearable
					placeholder="请输入商户编号"
					style="width: 240px"
					size="small"
					@keyup.enter.native="handleQuery"
				/>
			</el-form-item>
			<el-form-item
				label="商户简称"
				prop="lsMerchantName"
				label-width="140px"
			>
				<el-input
					v-model="queryParams.lsMerchantName"
					clearable
					placeholder="请输入商户简称"
					style="width: 240px"
					size="small"
					@keyup.enter.native="handleQuery"
				/>
			</el-form-item>
			<el-form-item label="代理商编号" prop="agentNo" label-width="140px">
				<el-input
					v-model="queryParams.agentNo"
					clearable
					placeholder="请输入代理商编号"
					style="width: 240px"
					size="small"
					@keyup.enter.native="handleQuery"
				/>
			</el-form-item>
			<!-- <el-form-item label="商户状态" prop="merStatus" label-width="140px">
                <el-select v-model="queryParams.merStatus" size="small"  placeholder="请选择商户状态">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="待审核" value="1"></el-option>
                    <el-option label="审核失败" value="2"></el-option>
                    <el-option label="正常" value="3"></el-option>
                    <el-option label="注销" value="4"></el-option>
                </el-select>
            </el-form-item> -->
			<el-form-item label="渠道" prop="channelNo" label-width="140px">
				<el-select
					v-model="queryParams.channelNo"
					placeholder="请选择渠道"
					clearable
					size="small"
					style="width: 240px"
				>
					<el-option label="全部" value="" />
					<el-option
						v-for="(item, key) of dicts.CHANNEL"
						:key="key"
						:label="item"
						:value="key"
					/>
				</el-select>
			</el-form-item>
		</el-form>

		<el-row :gutter="10" class="mb8">
			<el-col :span="1.5">
				<el-button
					type="primary"
					icon="el-icon-search"
					size="mini"
					@click="handleQuery"
					v-permission="'MERCHANT_INFO_QUERY'"
				>
					查询
				</el-button>
				<el-button
					type="primary"
					icon="el-icon-add"
					size="mini"
					@click="handleAdd"
					v-permission="'MERCHANT_INFO_INSERT'"
				>
					新增商户
				</el-button>
				<el-button
					icon="el-icon-refresh"
					size="mini"
					@click="handleResetQuery"
					v-permission="'MERCHANT_INFO_QUERY'"
				>
					重置
				</el-button>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import { MerchantApi, TerminalApi } from "@/api";
import { mapState } from "vuex";
export default {
	name: "ToolBar",
	props: {
		dicts: {
			type: Object,
			default: {}
		}
	},
	data() {
		return {
			queryParams: {},
			createDateRange: [],
			updateDateRange: [],
			showCustomForm: false,
			activityList: []
		};
	},
	created() {
		TerminalApi.queryInfo.getActivityInfo().then(res => {
			if (res.success) {
				this.activityList = res.data;
			}
		});
	},
	computed: {
		...mapState("app", ["userInfo"]),
		isOrg() {
			return this.userInfo.org;
		}
	},
	methods: {
		handlerCustomFormOnclose() {
			this.showCustomForm = false;
			this.commitChange();
		},
		handleQuery() {
			this.commitChange();
		},
		handleResetQuery() {
			this.queryParams = {};
			this.createDateRange = [];
			this.updateDateRange = [];
			this.commitChange();
		},
		handleAdd() {
			this.$router.push({
				name: "merchantInsert"
			});
		},
		handleExport() {
			this.$confirm("确定导出业务用户（包含机构/代理商）", "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(async () => {
				this.dealWithQueryParams();
				let result = await MerchantApi.agentInfo.export(
					this.queryParams
				);
				if (result.success) {
					this.downloadFile(result.data);
				}
			});
		},
		dealWithQueryParams() {
			if(!this.createDateRange){
				this.createDateRange =[]
			}
			let [startCreateTime, endCreateTime] = this.createDateRange;
			this.queryParams.startCreateTime =
				startCreateTime && startCreateTime + " 00:00:00";
			this.queryParams.endCreateTime =
				endCreateTime && endCreateTime + " 23:59:59";

			let [startUpdateTime, endUpdateTime] = this.updateDateRange;
			this.queryParams.startUpdateTime =
				startUpdateTime && startUpdateTime + " 00:00:00";
			this.queryParams.endUpdateTime =
				endUpdateTime && endUpdateTime + " 23:59:59";
		},
		commitChange() {
			this.dealWithQueryParams();
			let params = { ...this.queryParams };
			this.$emit("on-change", params);
		},
		getDateRange(val) {
			this.queryParams.startCreateTime = val[0];
			this.queryParams.endCreateTime = val[1];
		}
	}
};
</script>

<style scoped></style>
